import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, forkJoin, from, of } from 'rxjs';
import { DataStorageService } from './data.service';
import { catchError, map, switchMap } from 'rxjs/operators';
import { RandomUniqueIdService } from './randomUniqueId.service';

@Injectable({
  providedIn: 'root'
})
export class DisbursmentService {
  disbursmentUrl = environment.ENDPOINTS.CLIENTS_URL

  constructor(
    private httpClient: HttpClient, private cacheStorageService: DataStorageService,
    private randomUniqueIdService: RandomUniqueIdService,
  ) { }

  getAllDisbursments(companyId: number, page: number, size: number, sort: any, status?: any, advncedFilters?: any, context?: boolean): Observable<any> {
    const queryParams = this.buildQueryParams(page, size, sort, status, advncedFilters);
    const requestUrl = `${this.disbursmentUrl}/${companyId}/disbursements`;
    // return this.httpClient.get<any>(req, { observe: 'response', params: queryParms });
    // if (!navigator.onLine && context) {
    //   return from(this.cacheStorageService.getAllItems('disbursements')).pipe(
    //     catchError(() => this.httpClient.get<any>(req, { observe: 'response', params: queryParms }))
    //   );
    // }
    if (!navigator.onLine && !context) {
      return this.getOfflineDisbursments(requestUrl, queryParams);
    }

    return this.getOnlineDisbursments(requestUrl, queryParams);
  }

  private buildQueryParams(page: number, size: number, sort: any, status?: any, advncedFilters?: any): HttpParams {
    const normalizedStatus = status?.length === 1 ? status[0].toString() : null;
    const baseParams = {
      page,
      size,
      sort: `${sort[0].prop},${sort[0].direction}`,
      status: normalizedStatus,
      ...advncedFilters
    };

    return Object.entries(baseParams).reduce((params, [key, value]) => {
      return value ? params.append(key, value.toString()) : params;
    }, new HttpParams());
  }

  private getOfflineDisbursments(requestUrl: string, params: HttpParams): Observable<any> {
    const storedData$ = from(this.cacheStorageService.getAllItems('disbursements')).pipe(
      catchError(() => this.httpClient.get<any>(requestUrl, { observe: 'response', params }))
    );

    const addedData$ = from(this.cacheStorageService.getAllItems('disbursementsAdded')).pipe(
      catchError(() => this.httpClient.get<any>(requestUrl, { observe: 'response', params }))
    );

    return forkJoin([storedData$, addedData$]).pipe(
      map(([storedData, addedData]) => [...storedData, ...addedData])
    );
  }

  private getOnlineDisbursments(requestUrl: string, params: HttpParams): Observable<any> {
    return this.httpClient.get<any>(requestUrl, { observe: 'response', params }).pipe(
      switchMap(response => of(response))
    );
  }

  getDisbursmentById(companyId, disbursement) {
    const req = this.disbursmentUrl + `/${companyId}/disbursements/${(disbursement instanceof Object) ? disbursement.id : disbursement}`;
    // return this.httpClient.get<any>(req, {observe: 'response'});
    if (!navigator.onLine) {
      return from(this.cacheStorageService.getItem(disbursement.id, disbursement.offlineReference ? 'disbursementsAdded' : 'disbursements')).pipe(
        catchError(() => this.httpClient.get<any>(req, { observe: 'response' }))
      );
    }
    return this.httpClient.get<any>(req, { observe: 'response' }).pipe(
      switchMap(response => {
        this.cacheStorageService.setItem(req, response);
        return of(response);
      })
    );

  }

  deleteDisbursment(companyId, interestType, id) {
    const req = this.disbursmentUrl + `/${companyId}/disbursements/${interestType}/${id}`;
    return this.httpClient.delete<any>(req);
  }
  createDisbursment(companyId, invoiceAmount, interestType): Observable<any> {
    const req = this.disbursmentUrl + `/${companyId}/disbursements/${interestType}`;
    // return this.httpClient.post<any>(req, invoiceAmount);
    if (!navigator.onLine) {
      const bodyOffline = {
        ...invoiceAmount,
        id: new Date().toISOString(),
        interestType: interestType,
        offlineReference: this.randomUniqueIdService.generateUniqueId('DEC-'),
      }
      this.cacheStorageService.addItem([bodyOffline], 'disbursementsAdded');
      return from(this.cacheStorageService.getItem(bodyOffline.id, 'disbursementsAdded', true)).pipe(
        catchError(() =>
          this.httpClient.post<any>(req, invoiceAmount))
      );
    }
    const str = JSON.stringify(invoiceAmount);
    const fd = new FormData();
    fd.append(
      "disbursement",
      new File([str], "disbursement.json", { type: "application/json" })
    );
    if (invoiceAmount.attachment) {
      fd.append("attachment", invoiceAmount.attachment);
    }
    return this.httpClient.post<any>(req, fd).pipe(
      switchMap(response => {
        return of(response);
      })
    );
  }
  updateDisbursment(companyId, id, invoiceAmount: FormData, interestType?: any) {
    const req = this.disbursmentUrl + `/${companyId}/disbursements/${interestType}/${id}`;
    return this.httpClient.put<any>(req, invoiceAmount);
  }
  updateStatus(companyId, id, status, interestedType) {
    const req = this.disbursmentUrl + `/${companyId}/disbursements/${interestedType}/${id}/update-status?status=${status}`;
    return this.httpClient.post<any>(req, status);
  }

}
